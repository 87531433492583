export const routes = [
  {
    path: 'projectManagement',
    name: 'projectManagement',
    component: () => import('@/views/projectManagement/index.vue'),
    meta: {
      title: '项目管理'
    },
    redirect: '/system/projectManagement/overview',
    children: [
      {
        path: 'overview',
        name: 'overview',
        component: () => import('@/views/projectManagement/overview.vue'),
        meta: {
          title: '项目总览'
        },
      },
      {
        path: 'projectDetails',
        name: 'projectDetails',
        component: () => import('@/views/projectManagement/projectDetails.vue'),
        meta: {
          title: '项目详情'
        },
      },
      {
        path: 'projectTodo',
        name: 'projectTodo',
        component: () => import('@/views/projectManagement/projectTodo.vue'),
        meta: {
          title: '我的待办'
        },
      },
      {
        path: 'projectCreate',
        name: 'projectCreate',
        component: () => import('@/views/projectManagement/projectCreate.vue'),
        meta: {
          title: '我创建的'
        },
      },
      {
        path: 'projectHandle',
        name: 'projectHandle',
        component: () => import('@/views/projectManagement/projectHandle.vue'),
        meta: {
          title: '我处理的'
        },
      },
      {
        path: 'projectSearch',
        name: 'projectSearch',
        component: () => import('@/views/projectManagement/projectSearch.vue'),
        meta: {
          title: '需求搜索'
        },
      },
      {
        path: 'projectTask',
        name: 'projectTask',
        component: () => import('@/views/projectManagement/projectTask.vue'),
        meta: {
          title: '项目任务'
        }
      },
      {
        path: 'projectTask/projectTaskDetails',
        name: 'projectTaskDetails',
        component: () => import('@/views/projectManagement/projectTaskDetails.vue'),
        meta: {
          title: '任务详情'
        }
      },
      {
        path: 'projectTask/projectRequireDetails',
        name: 'projectRequireDetails',
        component: () => import('@/views/projectManagement/projectRequireDetails.vue'),
        meta: {
          title: '需求详情'
        }
      },
    ]
  }
]
export default [...routes]