export const routes = {
  path: 'recruitment',
  name: 'recruitment',
  component: () => import('@/views/recruitment/index.vue'),
  meta: {
    title: '招聘管理'
  },
  children: [
    {
      path: 'resume',
      name: 'resume',
      component: () => import('@/views/recruitment/resumeManage.vue'),
      meta: {
        title: '简历管理'
      }
    },
    {
      path: 'station',
      name: 'station',
      component: () => import('@/views/recruitment/stationManage.vue'),
      meta: {
        title: '岗位管理'
      }
    },
    {
      path: 'offer',
      name: 'offerManagement',
      component: () => import('@/views/recruitment/offerManage.vue'),
      meta: {
        title: 'OFFER管理'
      }
    }
  ]
}

export default [routes]
