import departmentRoutes from './department'
import loginRoutes from './login'
import navRoutes from './nav'
import recruitmentRoutes from './recruitment'
import userinfo from './userinfo'
import taskManagement from './taskManagement'
import authManagement from './authManagement'
import fileManagement from './fileManagement'
import projectManagement from './projectManagement'
import offerRoutes from './offer'

export const staticRoutes = [
  ...navRoutes,
  {
    path: '/system',
    name: 'system',
    component: () => import('@/layout/index.vue'),
    meta: {
      title: '极创智梦工作室内部系统'
    },
    children: [...recruitmentRoutes, ...departmentRoutes, ...taskManagement, ...userinfo, ...authManagement, ...fileManagement, ...projectManagement]
  },
  ...loginRoutes,
  ...offerRoutes
]

export default staticRoutes
