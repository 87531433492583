export const routes = {
  path: 'taskManagement',
  name: 'taskManagement',
  component: () => import('@/views/taskManagement/index.vue'),
  meta: {
    title: '任务管理'
  },
  // redirect: '/system/taskManagement/statistics',
  children: [
    {
      path: 'overview',
      name: 'taskOverview',
      component: () => import('@/views/taskManagement/overview.vue'),
      meta: {
        title: '任务总览'
      }
    },
    {
      path: 'participateState',
      name: 'participateState',
      component: () => import('@/views/taskManagement/participateState.vue'),
      meta: {
        title: '任务参与情况'
      }
    },
    {
      path: 'statistics',
      name: 'statistics',
      component: () => import('@/views/taskManagement/statistics.vue'),
      meta: {
        title: '任务统计'
      }
    }
  ]


}

export default [routes]
