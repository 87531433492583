export const routes = {
  path: '/',
  name: 'nav',
  component: () => import('@/views/nav/index.vue'),
  meta: {
    title: '极创智梦工作室内部系统'
  }
}

export default [routes]
