export const routes = [
  {
    path: 'authManagement',
    name: 'authManagement',
    component: () => import('@/views/authManagement/index.vue'),
    meta: {
      title: '权限管理'
    },
    redirect: '/system/authManagement/systemAuth',
    children: [
      {
        path: 'systemAuth',
        name: 'systemAuth',
        component: () => import('@/views/authManagement/systemAuth.vue'),
        meta: {
          title: '系统权限总览'
        }
      },
      {
        path: 'memberAuth',
        name: 'memberAuth',
        component: () => import('@/views/authManagement/memberAuth.vue'),
        meta: {
          title: '成员权限总览'
        }
      }
    ]
  }
]
export default [...routes]