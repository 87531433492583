import { createRouter, createWebHistory } from 'vue-router'
import staticRoutes from './static/index'
import errorRoutes from './static/error'

const routes = [
  ...staticRoutes,
  ...errorRoutes
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
