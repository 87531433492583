export const notFound = {
  path: '/:pathMatch(.*)*',
  name: 'NotFound',
  component: () => import('@/views/error/404.vue'),
  meta: {
    title: '页面未找到',
  },
  redirect: '/error/404',
  children: []
}

export const errorRoute = {
  path: '/error',
  name: 'error',
  component: () => import('@/views/error/index.vue'),
  meta: {
    title: '错误页',
  },
  children: [
    {
      path: '404',
      name: '页面未找到′⌒`',
      meta: {
        title: '404',
      },
      component: () => import('@/views/error/404.vue')
    }
  ]
}

export default [errorRoute, notFound]
