export const routes = {
  path: 'department',
  name: 'department',
  component: () => import('@/views/department/index.vue'),
  meta: {
    title: '部门管理'
  }
}

export default [routes]
