export const routes = {
  path: '/login',
  name: 'login',
  component: () => import('@/views/user/login.vue'),
  meta: {
    title: '用户登录'
  }
}

export default [routes]
