export const routes = [
  {
    path: 'fileManagement',
    name: 'fileManagement',
    component: () => import('@/views/fileManagement/index.vue'),
    meta: {
      title: '文件管理'
    },
    redirect: '/system/fileManagement/TeamCloudDisk?groupId=0',
    children: [
      {
        path: 'TeamCloudDisk',
        name: 'TeamCloudDisk',
        component: () => import('@/views/fileManagement/TeamCloudDisk.vue'),
      }
    ]
  }
]
export default [...routes]