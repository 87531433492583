export const routes = {
  path: 'userinfo',
  name: 'userinfo',
  component: () => import('@/views/user/index.vue'),
  meta: {
    title: '个人中心'
  },
  children: [
    {
      path: 'userinfos',
      name: 'userinfos',
      component: () => import('@/views/user/userinfos.vue'),
      meta: {
        title: '个人信息'
      }
    },
    {
      path: 'userinfoEmail',
      name: 'userinfoEmail',
      component: () => import('@/views/user/userinfoEmail.vue'),
      meta: {
        title: '个人邮箱绑定'
      }
    },
    {
      path: 'userinfoModifypwd',
      name: 'userinfoModifypwd',
      component: () => import('@/views/user/userinfoModifyPwd.vue'),
      meta: {
        title: '密码修改'
      }
    }
  ]
}

export default [routes]
